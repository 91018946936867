import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Order } from '@app/modules/booking/models/OrderModel';
import { BookingServiceService } from '@app/modules/booking/service/booking-service.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-item-erp',
  templateUrl: './modal-item-erp.component.html',
  styleUrls: ['./modal-item-erp.component.scss']
})
export class ModalItemErpComponent implements OnInit {
    @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
    @Input() body_title: string = '';
    @Input() body_text: string = '';
    @Input() buttonSubmitText: string = 'ตกลง';
    @Output() modalReference!: NgbModalRef;
    @Output() btnModalDangerEvent = new EventEmitter<any>();
    @Input() bookingId: number = 0;
  
    @ViewChild("modalItemErp", { static: true }) modalItemErp!: TemplateRef<any>;
    orders: Order[] = [];
    selectOrder: string | null = null;
    constructor(private modalService: NgbModal,
      public BS: BookingServiceService,
    ) { }
  
    ngOnInit(): void {
    }
  
    openmodal(size?: 'sm' | 'lg' | 'xl' | string) {
      if (size != undefined && size != '' && size != null) {
        this.modalReference = this.modalService.open(this.modalItemErp, { size: size, centered: true });
      }
      else {
        this.modalReference = this.modalService.open(this.modalItemErp, { centered: true });
      }
      this.GetOrderHistories();
    }
  
    btnsubmit_click() {
      this.modalReference.close();
      this.btnModalDangerEvent.emit();
    }

    GetOrderHistories(): void {
      this.BS.getOrderHistories(this.bookingId).subscribe(
        (res) => {
          this.orders = res;
        },
        (err) => {
          console.error(err);
        }
      );
    }

}
