import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Order, OrderItem } from '@app/modules/booking/models/OrderModel';
import { BookingServiceService } from '@app/modules/booking/service/booking-service.service';
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-booking-detail-order-table',
  templateUrl: './booking-detail-order-table.component.html',
  styleUrls: ['./booking-detail-order-table.component.scss'],
})
export class BookingDetailOrderTableComponent implements OnInit, OnChanges {
  @Input() orders: Order[] = [];
  @Input() orderItemLimit: number = 5;
  @Input() selectOrderRef: string | null = null;
  @Input() showTable: boolean = true;

  @Input() shipmentChannel: string | undefined;
  
  @Input() orderQuery: {
    truckBookingId: number | null;
    truckbookingLocationId: number | null;
  } = {
    truckBookingId: null,
    truckbookingLocationId: null,
  };
  @Output() onClose = new EventEmitter<void>();
  @ViewChild('modelOrderDetail') modelOrderDetail: NgbModalRef;

  selectedOrder: Order[] = [];
  @Input() isShowErpDetailData: boolean = false;
  @Input() showOrderItemsTable: boolean = true;

  constructor(
    private modalService: NgbModal,
    private bookingService: BookingServiceService
  ) {}

  ngOnInit(): void {

  }

  ngOnChanges(change: SimpleChanges): void {

   //this.isShowErpDetailData =  this.orders.find(x=> x.isShowErpData) ? true: false;
    if (this.selectOrderRef) {
      const order: Order = this.orders.find(
        (order) => order.ref_No_1 === this.selectOrderRef
      );
      order && this.openModal(order);
    }

    if (change.orderQuery?.currentValue?.truckBookingId) {
      // setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => {
        this.getOrder(
          change.orderQuery?.currentValue?.truckBookingId,
          change.orderQuery?.currentValue?.truckbookingLocationId
        );
      }, 10);
    }
  }

  getOrder(truckBookingId: number, truckbookingLocationId?: number): void {
    this.bookingService
      .getOrderHistories(truckBookingId, truckbookingLocationId)
      .subscribe(
        (res) => {
          this.selectedOrder = res.map((order) => ({
            ...order,
            showLimit: true,
          }));
          this.openModal();
        },
        (err) => {
          console.error(err);
        }
      );
  }

  openModal(order?: Order): void {

    if (!this.showOrderItemsTable)
      return;

    if (order) {
      if (!order.orderItems?.length) {
        return;
      }
      this.selectedOrder = [{ ...order, showLimit: true }];
    }

    this.modelOrderDetail = this.modalService.open(this.modelOrderDetail, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });

    this.modelOrderDetail.result.then(
      (result) => {},
      (reason) => {
        if (
          reason === ModalDismissReasons.ESC ||
          reason === ModalDismissReasons.BACKDROP_CLICK
        ) {
          this.onClosedModal();
        }
      }
    );
  }

  onClosedModal(): void {
    this.modalService.dismissAll();
    this.selectedOrder = [];
  }

  removeTrailingComma(str1?: string,str2?: string,str3?: string): string {
    let result: string[] = [];

    // Remove trailing comma from each string and add it to the result array if it's not empty
    if (str1) {
      result.push(str1.replace(/,\s*$/, ''));
    }
    if (str2) {
      result.push(str2.replace(/,\s*$/, ''));
    }
    if (str3) {
      result.push(str3.replace(/,\s*$/, ''));
    }

    // Join non-empty strings with a comma, or return '-' if no strings were added
    return result.length > 0 ? result.join(', ') : '-';
  }

  toggleShowLimit(index: number): void {
    this.selectedOrder[index].showLimit = false;
  }

  sumQtuActualForErp(items:OrderItem[]){
    var sum = 0;
    items.forEach(x=>{
      sum+= Number(x.erpStatusActual)
    });
    return sum;
  }
  sumQtuPlanForErp(items:OrderItem[]){
    var sum = 0;
    items.forEach(x=>{
      sum+= Number(x.erpStatusPlan)
    });
    return sum;
  }
}
