import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'app-icon-selector',
  templateUrl: './icon-selector.component.html',
  styleUrls: ['./icon-selector.component.scss'],
})
export class IconSelectorComponent implements AfterViewInit {
  @Input() selectedIcon: number = 0;
  @Input() isClassNameChild: boolean = false;
  @Input() shipmentId: number | undefined;

  @Output() isSelected: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('tooltipHtmlContent') tooltipHtmlContent!: TemplateRef<any>;

  tooltipContent: string = `
    <svg class="icon pointer" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="gray" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="width: 20px;height:20px;margin-bottom: 5px">
      <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
    </svg>
    <span>ยังไม่ได้ทำ Pre-trip</span>
    <br>
    <svg class="icon pointer" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="width: 20px;height:20px;margin-bottom: 5px">
      <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
    </svg>
    <span>ทำ Pre-trip ไม่ผ่าน</span>
    <br>
    <svg class="icon pointer" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="#fbbd05" stroke="#fbbd05" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="width: 20px;height:20px;margin-bottom: 5px">
      <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
      <polyline points="9 12 12 15 16 10" fill="none" stroke="white"></polyline>
    </svg>
    <span>ทำ Pre-trip ไม่ผ่าน แต่มีการอนุมัติให้ผ่าน</span>
    <br>
    <svg class="icon pointer" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="#00c88a" stroke="#00c88a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="width: 20px;height:20px;margin-bottom: 5px">
      <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
      <polyline points="9 12 12 15 16 10" fill="none" stroke="white"></polyline>
    </svg>
    <span>ทำ Pre-trip ผ่าน</span>
    <br>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 80" style="width: 20px;height:25px;margin-bottom: 5px">
  <path
    d="M32 4L10 12v18c0 13.1 9.4 24.4 22 28.9 12.6-4.5 22-15.8 22-28.9V12L32 4z"
    fill="red"
    stroke="red"
    stroke-width="3"
  />
  <path
    d="M20 20l24 24M44 20L20 44"
    fill="none"
    stroke="white"
    stroke-width="3"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
    <span>ไม่อนุมัติ</span>`;

  tooltipContentX: string = '';

  constructor(
    private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    //if (this.selectedIcon == undefined) this.selectedIcon = 1;
   // this.cdr.detectChanges();
  }

  selectIcon(select: boolean): void {
    this.isSelected.emit(select);
  }
}
