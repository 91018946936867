<div class="row"
    *ngIf="showTable">
  <div class="col-12">
    <table class="table table-bordered table-hover table-striped w-100 table-sm">
      <thead class="bg-fusion-500">
        <tr>
          <th class="text-center"
              width="7%">#</th>
          <th>Ref.No</th>
          <th class="text-center"
              width="10%">จำนวน SKU</th>
          <th class="text-center"
              width="10%">ประเภทสินค้า</th>
          <th class="text-center"
              width="10%">น้ำหนัก</th>
              <th *ngIf="isShowErpDetailData">Sap Create Shipment Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of orders;let i = index;" style="cursor: pointer;" (click)="openModal(order, modelOrderDetail)">
          <td class="text-center">{{i+1}}</td>
          <td
              [ngClass]="{'disabled-order': !order.isValid, 'link openModal': showOrderItemsTable}">
            {{removeTrailingComma(order.ref_No_1,order.ref_No_2,order.ref_No_3)}}{{ !order.isValid ? ' X' : '' }}
          </td>
          <td class="text-center">
            {{order.skuQuantity ? order.skuQuantity: "-"}}
          </td>
          <td class="text-center">
            {{order.productType ? order.productType: "-"}}</td>
          <td class="text-left">
            <div *ngIf="order.totalWeight">{{order.totalWeight | number:'1.2'}} กิโลกรัม</div>
            <div *ngIf="!order.totalWeight">- กิโลกรัม</div>
          </td>
          <td *ngIf="isShowErpDetailData">
            <span [ngClass]="{'text-red':sumQtuActualForErp(order.orderItems)== 0 }">{{sumQtuActualForErp(order.orderItems)}}</span>
             / {{sumQtuPlanForErp(order.orderItems)| number:'1.2'}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Modal -->
<ng-template #modelOrderDetail
    let-modal>
  <div class="modal-content"
      [ngStyle]="{'min-width': '700px'}">
    <div class="modal-body">
      <ng-container *ngFor="let order of selectedOrder;let index = index">
        <div [ngStyle]="{'padding-top': index > 0 ? '20px' : '0px'}"
            class="d-flex justify-content-between"
            style="padding-bottom: 20px; align-items: baseline;">
          <h5 class="modal-title"
              id="modal-basic-title">
            รายละเอียด D/O (Ref no : {{ removeTrailingComma(order?.ref_No_1,order?.ref_No_2,order?.ref_No_3) }}) รวมทั้งหมด
            {{order?.orderItems?.length || 0}} รายการ
          </h5>
          <button *ngIf="index === 0"
              type="button"
              class="close"
              aria-label="Close"
              (click)="onClosedModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <table class="table table-bordered table-hover table-striped w-100 table-sm"
            [ngClass]="{'disabled-table': !order.isValid }">
          <thead class="bg-fusion-500">
            <tr>
              <th class="text-center">
                No.
              </th>
              <th class="text-center">รหัสสินค้า</th>
              <th class="text-center">
                รหัส Barcode
              </th>
              <th>
                ชื่อสินค้า
              </th>
              <th class="text-center">
                จำนวน
              </th>
              <th class="text-center">
                จำนวนหน่วยยก
              </th>
              <th class="text-center">
                น้ำหนัก
              </th>
              <th class="text-center" *ngIf="order.isShowErpData">
                Sap Create Shipment Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of order.orderItems;let i = index;">
              <ng-container *ngIf="i < orderItemLimit || !order.showLimit">
                <td class="text-center">
                  {{i+1}}
                </td>
                <td class="text-center">
                  {{item.productNumber}}
                </td>
                <td class="text-center">
                  {{item.barcodeNumber || '-'}}
                </td>
                <td>
                  {{item.productName}}
                </td>
                <td class="text-center">
                  {{item.packagingVolume}} {{item.packagingUnit}}
                </td>
                <td class="text-center">
                  {{item.handlingVolume}} {{item.handlingUnit}}
                </td>
                <td class="text-left">
                  {{item.volume | number:'1.2'}} {{item.volumnUnit}}
                </td>
                <td class="text-left" *ngIf="order.isShowErpData">
                 <span [ngClass]="{'text-red':item.erpStatusActual == 0 }">{{item.erpStatusActual | number:'1.2'}}</span> / {{item.erpStatusPlan | number:'1.2'}}
                </td>

              </ng-container>
            </tr>
          </tbody>
        </table>
        <div class="d-flex w-100 justify-content-end">
          <button class="btn btn-dark"
              *ngIf="order?.orderItems?.length > orderItemLimit && order.showLimit"
              (click)="toggleShowLimit(index)">
            รายการเพิ่มเติม
          </button>
        </div>
      </ng-container>
    </div>
  </div>

</ng-template>
