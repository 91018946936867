<ng-template #modalItemErp let-modal>
  <div class="modal-header text-center">
    <div class="col-12 p-0">
      <button type="button" class="close pt-0 pl-0 pb-0 pr-1" style="color:black !important" aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body pb-0">
    <app-booking-detail-order-table [orders]="orders" [selectOrderRef]="selectOrder" [isShowErpDetailData]="true" [showOrderItemsTable]="false" [showTable]="true"></app-booking-detail-order-table>
  </div>
  <div class="modal-footer">
    <!-- <div class="col-12">
      <button id="btnsubmit" type="button" class="btn btn-block btn-outline-primary waves-effect waves-themed font-weight-bold"
      (click)="btnsubmit_click()">{{buttonSubmitText}}</button>
    </div> -->

  </div>
</ng-template>
