import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import * as $ from 'jquery';
import { User } from '../../_Models/UserModel/User';
import { AuthenticationService, HttpService } from '../../_Services';
import { MenuDto, MenuResponse } from '@app/core/_Models/UserModel/MenuDto';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import {
  ActivatedRoute,
  PRIMARY_OUTLET,
  Router,
  UrlSegment,
  UrlSegmentGroup,
} from '@angular/router';
import { MessagingService } from '@app/core/_Services/messaging.service';
import { ClientModule } from '@app/core/_Models/UserModel/UserPermission';
import { environment } from 'src/environments/environment';
import { OnbordingService } from '@app/core/_Services/onbording.service';
import { UserEventService } from '@app/core/_Services/user-event.service';

@Component({
  selector: 'app-slidemenu',
  templateUrl: './slidemenu.component.html',
  styleUrls: ['./slidemenu.component.scss'],
})
export class SlidemenuComponent implements OnInit, AfterViewInit {
  navMenuToggle = false;
  t;
  st;
  currentUser: User;
  menu: MenuDto[];
  menuNew = [];
  photoImage: string;
  basicMenus: MenuResponse[] = new Array<MenuResponse>();
  clientModule: ClientModule;
  @ViewChild('rla') routeLnkElm: any;
  currentMenuId: number = 0;
  currentParentMenuId: number = 0;
  currentUrl: string = "";
  tmsLogo: string = "assets/img/360techx/360techx_logo_horizontal_nobg_white 2.png";

  constructor(
    public auth: AuthenticationService,
    private _api: HttpService,
    private router: Router,
    private messagingService: MessagingService,
    private detech: ChangeDetectorRef,
    private _onbord: OnbordingService,
    private _userEvent: UserEventService
  ) {
    this.auth.currentUser.subscribe((x) => {
      this.currentUser = x;
      console.log("this.currentUser", this.currentUser)
      if (this.currentUser.Role.roleID == 9) {
        window.location.href = environment.IdentityUrl + '/login/tst360';
      }

      switch (this.currentUser.registerPackage?.id) {
        case "BC":
          this.tmsLogo = "assets/img/logo/tmsbasicforblack.png";
          break;
        case "PC":
          this.tmsLogo = "assets/img/logo/tmspremiumforblack.png";
          break;
        default:
          this.tmsLogo = "assets/img/360techx/360techx_logo_horizontal_nobg_white 2.png"
          break;
      }
    });
  }

  ngOnInit(): void {
    const userId = this.currentUser.tstS360_UserID;
    if (userId)
      this.messagingService.requestPermission(userId);
    this.messagingService.receiveMessage(this.currentUser.ClientID);
    let message = this.messagingService.currentMessage;
    console.log('messagingService.currentMessage  :', message);
    this.clientModule = this.auth.currentUserValue?.clientModules.find(e => e.amo_id == 110010);
    console.log('clientModule', this.clientModule);
    this.currentUrl = this.router.routerState.snapshot.url
    this.auth.currentUser.subscribe((img) => {
      if (img) {
        this.photoImage = img.photoURL;
        this.auth.photoAsync.next(img.photoURL);
        this.detech.detectChanges();
      }
    });

   
  }
ngOnChanges(changes: SimpleChanges): void {
  //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //Add '${implements OnChanges}' to the class.
 
}
  onRedirect(url: string) {
    console.log('url', url);
    if (url.includes("http") || url.includes("https")) {
      url = url.replace("{clientId}", this.auth.currentUserValue.ClientID.toString());
      // url = url.replace("{locationId}", this.auth.currentUserValue.ClientID.toString());
      window.open(url, '_blank');
      return;
    }
    let parsedUrl = this.router.parseUrl(url);
    let g: UrlSegmentGroup = parsedUrl.root.children[PRIMARY_OUTLET];
    let s: UrlSegment[] = g?.segments;
    if (s) {
      let formattedUrl = `${s.map((p) => p.path).join('/')}`; // this is required to combine all continued segment
      this.router.navigate(['/' + formattedUrl], {
        queryParams: parsedUrl.queryParams,
      });
    } else {
      this.router.navigateByUrl(url);
    }
  }
  urlDecoder(url: any): any {
    console.log('url', url);
    let parsedUrl = this.router.parseUrl(url);
    let g: UrlSegmentGroup = parsedUrl.root.children[PRIMARY_OUTLET];
    let s: UrlSegment[] = g?.segments;
    if (s) {
      let formattedUrl = `${s.map((p) => p.path).join('/')}`; // this is required to combine all continued segment
      return {
        Uri: '/' + formattedUrl,
        params: parsedUrl.queryParams,
      };
    } else {
      return {
        Uri: '/' + url,
        params: parsedUrl.queryParams,
      };
    }
  }

  navTitleClick(navMenuID) {
    $('#' + navMenuID + ' ul').toggle(150);
    //  let className = this.elRef.nativeElement.className;
    //  if(className){
    //   this.elRef.nativeElement.classList.remove('active');
    //   this.elRef.nativeElement.classList.remove('open');
    //  }else{
    //   this.elRef.nativeElement.classList.add('active');
    //   this.elRef.nativeElement.classList.add('open');
    //  }
  }

  ngAfterViewInit(): void {
    this.currentUrl = this.router.routerState.snapshot.url
    this.auth.photoAsync.subscribe((img) => {
      if (img) {
        this.photoImage = img;
        this.detech.detectChanges();
      }
    });
    this._api
      .get('/Menu/from-user/' + this.currentUser.id)
      .subscribe((res: TMSAPIResponse) => {
        if (res.isSuccess) {
          this.menu = res.data;
        }
      });

    try {

      this._api
        .getWithoutHandleError('/Menu/newMenu/' + this.currentUser.ClientID)
        .subscribe((res: any) => {
          console.log("newMenu", res);
          this.menuNew = res;
          this.getBasicMenus();
        });
    } catch {
      this.getBasicMenus();

    }


    console.log('this.basicMenus', this.basicMenus);
    // this.navTitleClick('navMenu' + this.currentParentMenuId)

    setTimeout(() => {
      if (this.clientModule?.pkg_id != 30) {

        this._onbord.OnbordingMethod(1);
        console.log('this.OnbordingMethod');
      }
    }, 3000)
  }

  getBasicMenus() {
    this._api.get('/menu/crossPlatform/' + this.currentUser.id).subscribe(res => {
      console.log('menu/crossPlatform', res);
      this.basicMenus = res;
      // this.basicMenus.map(l1 => {
      //   if (l1.childrens.length > 0) {
      //     return l1.childrens.map(l2 => {
      //       if (l2.childrens.length > 0) {
      //         return l2.childrens.map(l3 => {
      //           if (l3.menuLink + (l3.queryParams ? l3.queryParams : '') == this.currentUrl) {
      //             this.currentMenuId = l3.parentMenuId
      //             return;
      //           }
      //         })
      //       } else {
      //         const menu = l2.childrens.find(e => e.menuLink + (e.queryParams ? e.queryParams : '') == this.currentUrl)
      //         if (menu) {
      //           this.currentMenuId = menu.menuId
      //           return;
      //         }
      //       }
      //     })
      //   }
      // })
    })

  }

  parseQueryParamToJson(queryParam: string): any {
    if (queryParam) {
      // console.log('JSON.parse(queryParam)', JSON.parse(queryParam));
      return JSON.parse(queryParam)
    }
    return;

  }

  onImgErrorProfile(event) {
    event.target.src = 'assets/img/demo/avatars/avatar-m.png';
  }

  onImgError(event) {
    event.target.src = 'assets/img/default/image-not-available.png';
  }

  enterPriseMenuCrossHandler(platformId: number, url: string, queryParams: any) {
    console.log('enterPriseMenuCrossHandler', platformId, url, queryParams);
    if (platformId == 1) {
      window.open(environment.webBase360 + url + (queryParams ? queryParams : ''), '_blank')
      return;
    }
  }

  MenuCrossHandler(e: any, menu: MenuResponse) {
    this._userEvent.SaveUserAction(menu.menuName);

    if (menu.menuLink?.includes("http") || menu.menuLink?.includes("https")) {
      const url = (menu.menuLink.replace("{clientId}", this.auth.currentUserValue.ClientID.toString()))
      // url = url.replace("{locationId}", this.auth.currentUserValue.ClientID.toString());
      window.open(url, '_blank');
      return;
    }

    this.currentUrl = menu.menuLink + (menu.queryParams ? menu.queryParams : '');
    if (menu.childrens.length > 0) {
      if (this.currentMenuId == menu.menuId) {
        this.currentMenuId = 0;
      } else {
        this.currentMenuId = menu.menuId;
      }
    } else {
      this.currentMenuId = menu.menuId;
    }
    if (menu.childrens.length <= 0) {
      if (menu.platformId == 1) { // 1 = 360truck
        window.open(environment.webBase360 + menu.menuLink + (menu.queryParams ? menu.queryParams : ''), '_blank')
        return;
      }
      else { // 2,3 tms basic , premium
        this.router.navigateByUrl(this.currentUrl);
      }
    }
    this.navTitleClick('navMenu' + this.currentMenuId)


  }

  onMenuRedirect(menu: MenuResponse) {

    console.log('url', menu.menuLink);
    if (menu.menuLink?.includes("http") || menu.menuLink?.includes("https")) {
      const url = (menu.menuLink.replace("{clientId}", this.auth.currentUserValue.ClientID.toString()))
      // url = url.replace("{locationId}", this.auth.currentUserValue.ClientID.toString());
      window.open(url, '_blank');
      return;
    }
    else if (menu.platformId == 1) {
      // window.location.href = environment.web360 + menu.menuLink + (menu.queryParams ? menu.queryParams : '');
      window.open(environment.webBase360 + menu.menuLink + (menu.queryParams ? menu.queryParams : ''), '_blank')
      return;
    }
    else {
      this.currentUrl = menu.menuLink + (menu.queryParams ? menu.queryParams : '');

      let parsedUrl = this.router.parseUrl(menu.menuLink + menu.queryParams ? menu.queryParams : '');
      let g: UrlSegmentGroup = parsedUrl.root.children[PRIMARY_OUTLET];
      let s: UrlSegment[] = g?.segments;
      this.currentParentMenuId = menu.parentMenuId
      this.navTitleClick('navMenu' + this.currentParentMenuId)

      // if (menu.childrens.length > 0)
      //   this.navTitleClick('navMenu' + this.currentMenuId)

      // this.currentMenuId = menu.menuId
      // if (this.currentMenuId == menu.menuId) {
      //   this.currentMenuId = 0;
      // } else {
      //   this.currentMenuId = menu.menuId;
      // }

      if (s) {
        let formattedUrl = `${s.map((p) => p.path).join('/')}`; // this is required to combine all continued segment
        this.router.navigate(['/' + formattedUrl], {
          queryParams: parsedUrl.queryParams,
        });
      } else {
        this.router.navigate([menu.menuLink], {
          queryParams: this.parseQueryParamToJson(menu.jsonQueryParams),
        });
        // this.router.navigateByUrl(menu.menuLink);
      }
    }

  }

  ExitEvent() {
    console.log("ExitEvent")
  }
  isNewmenu(menuId): boolean {
    try {

      // console.log(this.menuNew)
      var menu = this.menuNew.find(menu => menu.menuId === menuId);
      if (menu) {
        return menu.isNew;
      }
      return false

    }
    catch {
      return false
    }
  }
}
