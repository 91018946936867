<div class="card border mt-3">
  <div class="card-header py-2">
      <div class="card-title">
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        width="20"
        height="20"
      >
        <!-- Shield Outline -->
        <path
          d="M32 4L10 12v18c0 13.1 9.4 24.4 22 28.9 12.6-4.5 22-15.8 22-28.9V12L32 4z"
          fill="none"
          stroke="#34C759"
          stroke-width="3"
        />
        <!-- Checkmark -->
        <path
          d="M20 32l8 8 16-16"
          fill="none"
          stroke="#34C759"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
          <span class="fs-sm fw-500">ประวัติการทำแบบทดสอบล่าสุด</span>
      </div>
  </div>
  <div *ngIf="items.length > 0" class="card-body">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12">
        <table class="table table-bordered table-hover table-striped w-100 table-sm">
          <thead class="bg-table">
            <tr>
              <th>#</th>
              <th>ชื่อแบบทดสอบ</th>
              <th class="text-center">วัน/เวลา ทำแบบทดสอบล่าสุด</th>
              <th class="text-center">ผลการทดสอบ</th>
              <th class="text-center">สถานะผลทดสอบ</th>
              <th class="text-center">Ref. Booking</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of items; index as i">
              <tr>
                <td>{{i + 1 }}</td>
                <td>{{item?.preTripSurvey?.name}}</td>
                <td>{{item?.submitDate}}</td>
                <td>{{item?.score}}/{{item?.totalScore}}</td>
                <td>{{item?.status?.nameTH}}</td>
                
                <td class="text-center" (click)="onClickDetail(item?.bookingId,item?.shipmentId)">
                  <span style="font-weight: bold;text-decoration:underline;cursor: pointer;">{{item?.bookingNo}}</span>
                </td>
                
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div *ngIf="items.length == 0" class="card-body row">
      <div class="col-12 text-center">
          <span class="text-muted">ไม่มีข้อมูล</span>
      </div>
  </div>

  <div class="card-footer-custom">
      <!-- <div class="row">
          <div class="col-12">
              <app-modal-payment-history [driver_id]="driver_id"></app-modal-payment-history>
          </div>
      </div> -->
  </div>
</div>