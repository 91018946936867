import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DriverManagementRoutingModule } from './driver-management-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { MaterialsModule } from '../materials/materials.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalAddDriverComponent } from './component/modal-add-driver/modal-add-driver.component';
import { DriverListComponent } from './page/driver-list/driver-list.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ModalPaymentHistoryComponent } from './component/modal-payment-history/modal-payment-history.component';
import { ModalSalaryRateDetailComponent } from './component/modal-salary-rate-detail/modal-salary-rate-detail.component';
import { DriverDetailComponent } from './page/driver-detail/driver-detail.component';
import { ModalAccountFormComponent } from './component/modal-account-form/modal-account-form.component';
import { DriverListFilterComponent } from './component/driver-list-filter/driver-list-filter.component';
import { DriverListActionComponent } from './component/driver-list-action/driver-list-action.component';
import { ModalAddFleetDriverComponent } from './component/modal-add-fleet-driver/modal-add-fleet-driver.component';
import { ModalchangepasswordComponent } from './component/modalchangepassword/modalchangepassword.component';
import { ModalsuccessComponent } from './component/modalsuccess/modalsuccess.component';
import { ModalchangemobilenumberComponent } from './component/modalchangemobilenumber/modalchangemobilenumber.component';
import { ModalchangeemailComponent } from './component/modalchangeemail/modalchangeemail.component';
import { ModalTrainingHistoryFormComponent } from './component/modal-training-history-form/modal-training-history-form.component';
import { ModalTrainingHistoryDetailComponent } from './component/modal-training-history-detail/modal-training-history-detail.component';
import { ModalShareDriverDetailComponent } from './component/modal-share-driver-detail/modal-share-driver-detail.component';
import { ModalDriverPrintComponent } from './component/modal-driver-print/modal-driver-print.component';
import { DriverGenPdfTemplateComponent } from './component/driver-gen-pdf-template/driver-gen-pdf-template.component';
import { TMSFileuploadModule } from '@app/shared/Components/FileUpload/tmsfileupload/tmsfileupload.module';
import { ModalEditDriverFormComponent } from './component/modal-edit-driver-form/modal-edit-driver-form.component';
import { ModalChangeFleetDriverComponent } from './component/modal-change-fleet-driver/modal-change-fleet-driver.component';
import { GalleryItemComponent } from './component/gallery-item/gallery-item.component';
import { LightboxModule, LIGHTBOX_CONFIG } from 'ng-gallery/lightbox';
import { GalleryModule } from 'ng-gallery';
import { ChangeFleetFormComponent } from '../truck-license-management/components/change-fleet-form/change-fleet-form.component';
import { ModalAddFleetDriverFormComponent } from './component/modal-add-fleet-driver-form/modal-add-fleet-driver-form.component';
import { ProfileUploadComponent } from './component/profile-upload/profile-upload.component';
import { TstFileuploadComponent } from './component/tst-fileupload/tst-fileupload.component';
import { ModalAddUserSuccessComponent } from './component/modal-add-user-success/modal-add-user-success.component';
import { ModalPreTripDriverComponent } from './component/modal-pre-trip-driver/modal-pre-trip-driver.component';


@NgModule({
  imports: [
    CommonModule,
    DriverManagementRoutingModule,
    SharedModule,
    MaterialsModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskModule,
    NgxDropzoneModule,
    TMSFileuploadModule,
    LightboxModule,
    GalleryModule,
  ],
  providers: [
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: false,
        // panelClass: 'fullscreen'
      }
    }
  ],
  exports: [
    ModalAddFleetDriverComponent,
    ModalAddDriverComponent,
    DriverListComponent,
    ModalEditDriverFormComponent,
    ModalchangemobilenumberComponent,
    ModalchangeemailComponent,
    ModalchangepasswordComponent
  ],
  declarations: [ModalAddDriverComponent,
    DriverListComponent,
    ModalPaymentHistoryComponent,
    ModalSalaryRateDetailComponent,
    DriverDetailComponent,
    ModalAccountFormComponent,
    DriverListFilterComponent,
    DriverListActionComponent,
    ModalAddFleetDriverComponent,
    ModalchangepasswordComponent,
    ModalsuccessComponent,
    ModalchangemobilenumberComponent,
    ModalchangeemailComponent,
    ModalTrainingHistoryFormComponent,
    ModalTrainingHistoryDetailComponent,
    ModalShareDriverDetailComponent,
    ModalDriverPrintComponent,
    DriverGenPdfTemplateComponent,
    ModalEditDriverFormComponent,
    ModalChangeFleetDriverComponent,
    GalleryItemComponent,
    ModalAddFleetDriverFormComponent,
    ProfileUploadComponent,
    TstFileuploadComponent,
    ModalAddUserSuccessComponent,
    ModalPreTripDriverComponent
  ],
})
export class DriverManagementModule { }
