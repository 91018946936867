import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { HistoryDriverTestingDto } from '@app/modules/booking/models/HistoryDriverTestingModel';
import { BookingServiceService } from '@app/modules/booking/service/booking-service.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-history-driver-testing',
  templateUrl: './modal-history-driver-testing.component.html',
  styleUrls: ['./modal-history-driver-testing.component.scss'],
})
export class ModalHistoryDriverTestingComponent implements OnInit {
  @ViewChild('modalHistoryDriverTesting', { static: true })
  modalHistoryDriverTesting!: ModalHistoryDriverTestingComponent;
  modalReference!: NgbModalRef;

  @Input() modalConfig: ModalConfirmModel;
  @Input() bookingNo: string = '';
  @Input() shipmentId: number | undefined;

  driverTestingList: HistoryDriverTestingDto[] = [];

  iconClass = '';


  constructor(
    private modalService: NgbModal,
    private bs: BookingServiceService
  ) {}
  ngAfterViewInit(): void {
    this.iconClass = `${this.modalConfig?.iconClassName}`;
  }

  ngOnInit() {
    if (this.modalConfig) {
      this.iconClass = `${this.modalConfig?.iconClassName}`;
    }
  }
  open(size?: 'sm' | 'lg' | 'xl' | string): NgbModalRef {
    this.ngOnInit();
    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(
        this.modalHistoryDriverTesting,
        { size: size, centered: true, backdrop: 'static' }
      );
    } else {
      this.modalReference = this.modalService.open(
        this.modalHistoryDriverTesting,
        { centered: true }
      );
    }
    this.getHistoryDriverTesting(this.shipmentId);
    return this.modalReference;
  }

  modalSubmit(comfirm: boolean): void {
    this.modalReference.close(comfirm);
    // this.confirmEvent.emit(comfirm);
  }

  getHistoryDriverTesting(shipmentId: number) {
    this.bs.getHistoryDriverTesting(shipmentId).then((res) => {
      this.driverTestingList = res.data;
    });
  }

  onAgainTest(item:HistoryDriverTestingDto){
    this.bs.GetFormApprovedPreTrip(item.preTripSurveyResponsesId).then(res=>{
      const url = res?.data;
      if(url){
        window.open(url, '_blank');
      }
    });
  }
}
export class ModalConfirmModel {
  title: string = 'ลบออกจากระบบ';
  description: string = 'คุณต้องการจะลบรถบรรทุกออกจากระบบใช่ หรือ ไม่';
  iconClassName: string = 'fas fa-times text-danger';
  buttonList: ButtonList[] = [
    { text: 'ยืนยัน', class: 'btn-success', comfirm: true },
    { text: 'ยกเลิก', class: 'btn-outline-success', comfirm: true },
  ];
}

export class ButtonList {
  text: string;
  class: string;
  comfirm: boolean;
}
