<svg class="icon pointer" [id]="shipmentId" [ngClass]="{'open-event':isClassNameChild}" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="gray" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" *ngIf="selectedIcon == 1" (click)="selectIcon(true)" [appTippy]="tooltipContent">
  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
</svg>

<svg class="icon pointer" [id]="shipmentId"  [ngClass]="{'open-event':isClassNameChild}" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" *ngIf="selectedIcon == 2" (click)="selectIcon(true)" [appTippy]="tooltipContent">
  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
</svg>

<svg class="icon pointer" [id]="shipmentId" [ngClass]="{'open-event':isClassNameChild}" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="#fbbd05" stroke="#fbbd05" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" *ngIf="selectedIcon == 3" (click)="selectIcon(true)" [appTippy]="tooltipContent">
  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
  <polyline points="9 12 12 15 16 10" fill="none" stroke="white"></polyline>
</svg>

<svg class="icon pointer" [id]="shipmentId" [ngClass]="{'open-event':isClassNameChild}" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="#00c88a" stroke="#00c88a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" *ngIf="selectedIcon == 4" (click)="selectIcon(true)" [appTippy]="tooltipContent" >
  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
  <polyline points="9 12 12 15 16 10" fill="none" stroke="white"></polyline>
</svg>

<svg class="icon pointer" [id]="shipmentId" [ngClass]="{'open-event':isClassNameChild}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 80" *ngIf="selectedIcon == 5" (click)="selectIcon(true)" [appTippy]="tooltipContent">
  <path
    d="M32 4L10 12v18c0 13.1 9.4 24.4 22 28.9 12.6-4.5 22-15.8 22-28.9V12L32 4z"
    fill="red"
    stroke="red"
    stroke-width="3"
  />
  <path
    d="M20 20l24 24M44 20L20 44"
    fill="none"
    stroke="white"
    stroke-width="3"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
