import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, NotificationService, PageloadingService } from '@app/core/_Services';
import { ThaiDatePipe } from '@app/shared/Pipes/thaidate.pipe';
import { ClipboardService } from 'ngx-clipboard';
import { environment } from 'src/environments/environment';
import { ModalAccountFormComponent } from '../../component/modal-account-form/modal-account-form.component';
import { ModalSalaryRateDetailComponent } from '../../component/modal-salary-rate-detail/modal-salary-rate-detail.component';
import { ModalTrainingHistoryDetailComponent } from '../../component/modal-training-history-detail/modal-training-history-detail.component';
import { ModalTrainingHistoryFormComponent } from '../../component/modal-training-history-form/modal-training-history-form.component';
import { ModalShareDriverDetailComponent } from '../../component/modal-share-driver-detail/modal-share-driver-detail.component';
import {
  DriverDetailResponse,
  FleetDriverResponse,
  UserBookbankResponse,
  UserSalaryResponse,
  UserStatementMonth,
  UserTrainingResponse,
} from '../../models/driver-detail-response.model';
import { DriverDetailService } from '../../services/driver-detail.service';
import { ModalDriverPrintComponent } from '../../component/modal-driver-print/modal-driver-print.component';
import { startWith } from 'rxjs/operators';
import { ClientUserService } from '@app/modules/team/services/client-user.service';
import { AddClientUserModel } from '@app/modules/team/models/ClientUser/AddClientUserModel';
import { ModalEditDriverFormComponent } from '../../component/modal-edit-driver-form/modal-edit-driver-form.component';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { PermissionModule } from '@app/core/_Models/UserModel/UserPermission';
import { PermissionGroup } from '@app/core/_Models/UserModel/PermissionGroup';
import { AnalyticsServiceService } from '@app/core/_Services/analytics-service.service';
import { BookingServiceService } from '@app/modules/booking/service/booking-service.service';
import { PreTripSurveyResponseDto } from '../../models/pre-trip-survey.model';
declare function openModal(modelID): any;
declare function closeModal(ModalID): any;

@Component({
  selector: 'app-driver-detail',
  templateUrl: './driver-detail.component.html',
  styleUrls: ['./driver-detail.component.scss'],
})
export class DriverDetailComponent implements OnInit {
  element: HTMLElement;

  driverDetail = new DriverDetailResponse();
  performancePerMonthSelect = 0;
  monthSelected: any;

  fleetDriver = new FleetDriverResponse();
  userTrainings: UserTrainingResponse[] = [];
  userSalaries: UserSalaryResponse[] = [];
  userBookbank: UserBookbankResponse;
  userStatementMonths: UserStatementMonth[] = [];
  userStatementMonth = new UserStatementMonth();
  userStatementDates: any;

  driverDetailSharedCheckList: any;

  driver_id: number;

  sub: any;

  month: any[] = [];

  minDate = new Date();

  longThaiMonth = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ];

  copytext: string = '';

  cbContent: any;

  currentUser: User;

  selectedDataPrintList: any[] = [];

  companyTruckItem: any;

  hiddenTemplatePrint: boolean = true;

  accountHeaderText = 'เพิ่มบัญชี';

  accountAction = 'CREATE';

  isEdit = false;
  isViewUser = false;
  isDriver = true;
  userEditData: AddClientUserModel;

  @ViewChild('modaltraininghistoryform', { static: true })
  modaltraininghistoryform!: ModalTrainingHistoryFormComponent;
  @ViewChild('modaltraininghistorydetail', { static: true })
  modaltraininghistorydetail!: ModalTrainingHistoryDetailComponent;
  @ViewChild('modalsalaryratedetail', { static: true })
  modalsalaryratedetail!: ModalSalaryRateDetailComponent;
  @ViewChild('modalaccountform_edit', { static: true })
  modalaccountform_edit!: ModalAccountFormComponent;
  @ViewChild('modalaccountform_create', { static: true })
  modalaccountform_create!: ModalAccountFormComponent;
  @ViewChild('modalsharedriverdetail', { static: true })
  modalsharedriverdetail!: ModalShareDriverDetailComponent;
  @ViewChild('modaldriverprint', { static: true })
  modaldriverprint!: ModalDriverPrintComponent;
  @ViewChild('modaleditdriverform', { static: true }) modaleditdriverform!: ModalEditDriverFormComponent;


  permission130200: PermissionGroup = {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };

  permission130100: PermissionGroup = {
    canApprove: false,
    canPublish: false,
    canEdit: false,
    canDelete: false,
    canRead: false,
  };

  dataPreTrip: PreTripSurveyResponseDto[] = []; 


  constructor(
    // public pageload: PageloadingService,
    private driverDetailService: DriverDetailService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private authenticationService: AuthenticationService,
    private _ClientUserService: ClientUserService,
    private _noti: NotificationService,
    private change: ChangeDetectorRef,
    private anlaytic: AnalyticsServiceService,
    private bookingService: BookingServiceService,

  ) { }

  ngOnInit(): void {
    //this.pageload.hide();

    this.authenticationService.currentUser.subscribe((x) => {
      this.anlaytic.PushUserInfo2(x);
      this.currentUser = x;
      console.log(x);
      var p2: PermissionModule = this.authenticationService.UserPermissionModule(130000);
      console.log('permission p2', p2);
      if (p2) {
        this.permission130200 = this.authenticationService.FindPermissionAction(
          130200,
          p2.operations
        );
        console.log('permission130200', this.permission130200);

        this.permission130100 = this.authenticationService.FindPermissionAction(
          130100,
          p2.operations
        );
      }

    });

    this._Activatedroute.paramMap.subscribe((params) => {
      this.driver_id = +params.get('id');

      this.sub = this._Activatedroute.paramMap.subscribe((params) => {
        console.log(params);
        this.driver_id = +params.get('id');

        this.getFleetXDriver();
        this.getUserTraining();
        this.getUserSalary();
        this.getUserStatement();
        this.getUserBookbank();
        if (this.driver_id) {
          this.GetDiverPreTripResponseHistoryInProfile(this.driver_id);
        }
      });

      console.log('this.driver_id', this.driver_id);

      console.log(this.router.url); //  /routename
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }

  onOpenExportDriverDetailModal() {
    openModal('#exportDriverDetailModal');
  }

  onOpenPreviewPDF() {
    openModal('#previewPDF');
  }

  openmodaltraininghistoryform() {
    this.modaltraininghistoryform.modalsize = undefined;
    this.modaltraininghistoryform.openmodal(
      this.modaltraininghistoryform.modalsize
    );
  }

  openmodaltraininghistorydetail() {
    this.modaltraininghistorydetail.modalsize = undefined;
    // this.modaltraininghistorydetail.userTrainings = this.userTrainings
    this.modaltraininghistorydetail.openmodal(
      this.modaltraininghistorydetail.modalsize
    );
  }

  openmodalsalaryratedetail() {
    this.modalsalaryratedetail.modalsize = undefined;
    this.modalsalaryratedetail.openmodal(this.modalsalaryratedetail.modalsize);
  }

  openmodalaccountform() {
    switch (this.accountAction) {
      case 'CREATE':
        this.modalaccountform_create.modalsize = undefined;
        this.modalaccountform_create.openmodal(
          this.modalaccountform_create.modalsize
        );
        break;

      case 'EDIT':
        this.modalaccountform_edit.modalsize = undefined;
        this.modalaccountform_edit.openmodal(
          this.modalaccountform_edit.modalsize
        );
      default:
        break;
    }
  }

  openmodalsharedriverdetail() {
    this.modalsharedriverdetail.openmodal(this.modalaccountform_edit.modalsize);
  }

  getFleetXDriver() {
    this.driverDetailService
      .getFleetDriverByUserId(this.driver_id)
      .subscribe((res) => {
        this.fleetDriver = res.data;
        this.fleetDriver.driverURL = `${environment.webBasic}${this.router.url}`;
        this.driverDetail.fleetDriver = res.data;

        console.log(
          'this.driverDetail.fleetDriver',
          this.driverDetail.fleetDriver
        );

        this.driverDetailSharedCheckList = [
          {
            id: 'name',
            name: 'ชื่อ-นามสกุล',
            checked: false,
            detail: `${this.fleetDriver.firstName} ${this.fleetDriver.lastName}`,
          },
          {
            id: 'tel',
            name: 'เบอร์โทร',
            checked: false,
            detail: `${this.fleetDriver.mobileNo}`,
          },
          {
            id: 'idcard',
            name: 'เลขบัตรประชาชน',
            checked: false,
            detail: `${this.fleetDriver.idcard}`,
          },
          {
            id: 'yearold',
            name: 'อายุ',
            checked: false,
            detail: `${this.fleetDriver.yearOld}`,
          },
          {
            id: 'birthdate',
            name: 'วันเกิด',
            checked: false,
            detail: this.fleetDriver.dateOfBirth,
          },
          {
            id: 'url',
            name: 'URL (internal)',
            checked: false,
            detail: this.driverDetail.fleetDriver.driverURL,
          },
        ];
        this.change.detectChanges()
      });
  }

  async getUserTraining() {
    await this.driverDetailService
      .getUserTrainingByUserId(this.driver_id)
      .subscribe((res) => {
        console.log('getUserTraining', res.data);
        this.userTrainings = res.data;
        this.driverDetail.userTrainings = res.data;
      });
  }

  async getUserSalary() {
    await this.driverDetailService
      .getUserSalaryByUserId(this.driver_id)
      .subscribe((res) => {
        console.log('getUserSalary', res.data);
        this.userSalaries = res.data;
        this.driverDetail.userSalaries = res.data;
      });
  }

  async getUserStatement() {
    await this.driverDetailService
      .getUserStatementMonthsByUserId(this.driver_id)
      .subscribe((res) => {
        if (res.isSuccess) {
          if (res.data) {
            this.userStatementMonth = res.data[0];
            this.driverDetail.userStatementMonth = res.data[0];

            console.log('getUserStatatement', res.data);
            this.userStatementMonths = res.data;
            console.log('this.userStatementMonth', this.userStatementMonths);

            this.month = [];
            res.data.map((c) => {
              this.month.push({
                id: `${c.year}-${c.month}`,
                name: this.longThaiMonth[c.month - 1] + ' ' + (c.year + 543),
              });
            });

            console.log('before this.monthSelected', this.monthSelected);

            this.monthSelected = this.month[0].id

            this.getUserStatementByMonth();

            console.log('after this.monthSelected', this.monthSelected);
          }
        }
      });
  }

  async getUserBookbank() {
    await this.driverDetailService
      .getUserBookbankByUserId(this.driver_id)
      .subscribe((res) => {
        if (res.isSuccess) {
          console.log('getUserBookbank', res.data);

          if (res.data) {
            this.driverDetail.userBookbank = res.data;
            this.accountHeaderText = 'แก้ไขข้อมูล';
            this.accountAction = 'EDIT';
          }
          this.userBookbank = res.data;

          console.log('this.userBookbank', this.userBookbank);


        }
      });
  }

  onselectedMonth() {
    console.log(this.monthSelected);

    this.userStatementMonth = this.userStatementMonths.find(
      (c) => {
        let select = `${c.year}-${c.month}`
        return select == this.monthSelected.toString()
      }
    );
    this.getUserStatementByMonth();
  }

  async getUserStatementByMonth() {
    let select = this.monthSelected.toString().split("-");
    let year = +select[0]
    let month = +select[1]
    console.log('year', year);
    console.log('month', month);

    await this.driverDetailService
      .getUserStatementByUserId(this.driver_id, year, month)
      .subscribe((res) => {
        if (res.isSuccess) {
          console.log('onselectedMonth', res);
          this.userStatementDates = res.data;
          this.driverDetail.userStatementDates = res.data;
        }
      });
  }

  // buttonShareClick(): void {
  //   const dataModal = {
  //     fleetDriver: this.fleetDriver,
  //   }
  //   this.modalDriverShare.dataModal = dataModal;
  //   this.modalDriverShare.open('sm');
  // }

  selectedDataPrintEvent(event: any[]): void {
    // console.log('event', event);
    this.selectedDataPrintList = event
      .filter((r) => r.selected)
      .map((e) => e.id);
    // console.log('this.selectedDataPrintList', this.selectedDataPrintList);
  }

  buttonPrintClick(): void {
    this.modaldriverprint.open('sm');

    this.modaldriverprint.modalReferenace.closed.subscribe((res) => {
      if (res) {
        this.hiddenTemplatePrint = false;
        setTimeout(() => {
          window.print();
          this.hiddenTemplatePrint = true;
        }, 500);
      } else {
        this.hiddenTemplatePrint = true;
      }
    });
  }

  openmodaleditdriverform(tst360UserId: number, isView = false) {
    this.modaleditdriverform.modalsize = undefined;

    console.log('get edit user', tst360UserId);
    //this.pageload.show();
    this._ClientUserService
      .GetClientUserById(tst360UserId)
      .subscribe((res: TMSAPIResponse) => {
        //this.pageload.hide();
        if (!res.isSuccess) {
          this._noti.toasterror('ไม่สามารถดึงข้อมูลได้', res.message);
        } else {
          if (res.isSuccess) {
            this.isEdit = true;
            this.isViewUser = isView;
            const userData = res.data;
            console.log('userData : ', userData);
            console.log(
              'userData.userRoleDto.isActive : ',
              userData.userRoleDto.isActive
            );
            this.userEditData = new AddClientUserModel();
            this.userEditData.clientID = userData.clientID;
            this.userEditData.tsT360_CompanyID = userData.tsT360_CompanyID;
            this.userEditData.tsT360_UserID = userData.tsT360_UserID;
            this.userEditData.username = userData.username;
            this.userEditData.phoneNumber = userData.phoneNumber;
            this.userEditData.email = userData.email;
            this.userEditData.firstName = userData.firstName;
            this.userEditData.lastName = userData.lastName;
            this.userEditData.photoURL = userData.photoURL;
            this.userEditData.idCard = userData.iDcard;
            this.userEditData.taxId = userData.taxId;
            this.userEditData.roleUser.isActive = userData.userRoleDto.isActive;

            this.userEditData.tsT360_DefaultUserServiceType = 2;
            this.userEditData.departmentId = userData.departmentDto.id;

            this.userEditData.roleId = userData.userRoleDto.roleID;
            this.userEditData.teamIds.push(userData.teamDto.id);

            this.userEditData.teamUser = userData.teamUserDto;
            this.userEditData.roleUser = userData.userRoleDto;
            this.userEditData.userIDCard = userData.userIDCardDto;
            this.userEditData.userDriverLicence = userData.userDriverLicenceDto;

            console.log('map team id: ', userData.teamUserDto);
            if (userData.teamUserDto) {
              this.userEditData.teamIds = userData.teamUserDto.map((m) => m.teamID);
            }

            this.userEditData.tstUserId = userData.identityUserDto?.tstUserId;
            console.log('userEditData : ', this.userEditData);

            this.userEditData.birthDate = userData.birthDate

            const modalRef = this.modaleditdriverform.openmodal(this.modaleditdriverform.modalsize);
            modalRef.closed.subscribe(res => {
              if (res)
                this.AfterFormSubmit(res);
            })
          }
        }
      }, err => {
        //this.pageload.hide();
      });


  }

  async AfterFormSubmit(event: any) {
    // if (event) {
    //   // await this.getDriver();
    //   closeModal('#EditUser');
    // }
    await this.refreshEvent(event)
  }

  async refreshEvent(event: boolean) {
    if (event) {
      this.getFleetXDriver();
      await this.getUserTraining();
    }
    // location.reload();
  }

  GetDiverPreTripResponseHistoryInProfile(driverId: number) {
    this.bookingService.GetDiverPreTripResponseHistoryInProfile(driverId).then(res => {
        this.dataPreTrip = res?.data;
    })
  }
}
