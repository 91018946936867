import { Component, Input, OnInit } from '@angular/core';
import { PreTripSurveyResponseDto } from '../../models/pre-trip-survey.model';

@Component({
  selector: 'app-modal-pre-trip-driver',
  templateUrl: './modal-pre-trip-driver.component.html',
  styleUrls: ['./modal-pre-trip-driver.component.scss']
})
export class ModalPreTripDriverComponent implements OnInit {
@Input() items: PreTripSurveyResponseDto[] =[];
  constructor() { }

  ngOnInit() {

  }

  onClickDetail(truckBookingId:number,shipmentId:number ){
    window.open(`/booking/details/${truckBookingId}?t=TRACKING&sid=${shipmentId}`, '_blank')
  }

}
