import { Injectable } from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {take, takeUntil} from "rxjs/operators";
import { HttpService } from './http.service';
import { Subject } from 'rxjs';

// declare var toastr;
declare var Swal;
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private toastrService: ToastrService,
    private _apiService: HttpService
  ) {}

  firebaseMessage(html:string,action:string){

      this.toastrService.success(html,'แจ้งเตือนใหม่', {
        enableHtml: true,
        closeButton: true,
        timeOut: 5000,
        progressBar: true,
        progressAnimation: "decreasing"

       // toastClass: 'toast-success'
      }).onTap
        .pipe(take(1))
        .subscribe(() => this.toasterClickedHandler(action));

    //this.toastrService.success('message', 'title')
  }

  firebaseMessageError(html:string,action:string){

    this.toastrService.error(html,'แจ้งเตือนใหม่', {
      enableHtml: true,
      closeButton: true,
      timeOut: 5000,
      progressBar: true,
      progressAnimation: "decreasing"
     // toastClass: 'toast-success'
    }).onTap
      .pipe(take(1))
      .subscribe(() => this.toasterClickedHandler(action));

  //this.toastrService.success('message', 'title')
}

firebaseMessageWarning(html:string,action:string){

  this.toastrService.warning(html,'แจ้งเตือนใหม่', {
    enableHtml: true,
    closeButton: true,
    timeOut: 5000,
    progressBar: true,
    progressAnimation: "decreasing"
   // toastClass: 'toast-success'
  }).onTap
    .pipe(take(1))
    .subscribe(() => this.toasterClickedHandler(action));

//this.toastrService.success('message', 'title')
}

firebaseMessageInfo(html:string,action:string){

  this.toastrService.info(html,'แจ้งเตือนใหม่', {
    enableHtml: true,
    closeButton: true,
    timeOut: 5000,
    progressBar: true,
    progressAnimation: "decreasing"
   // toastClass: 'toast-success'
  }).onTap
    .pipe(take(1))
    .subscribe(() => this.toasterClickedHandler(action));

//this.toastrService.success('message', 'title')
}



  CreateMessage(title:string,text:string,action:string){
    if (text){
   //   this.toastr.show(text,title, {
      this.toastrService.show(text,title, {
        enableHtml: true,
        closeButton: true,
        timeOut: 60000,
        toastClass: 'toast-success',
        progressBar: true,
        progressAnimation: "decreasing"
      }).onTap
      .pipe(take(1))
      .subscribe(() => this.toasterClickedHandler(action));
    }

  }

  toasterClickedHandler(action) {
    console.log('Toastr clicked: '+action);
    window.open(action);
  }

  toastsuccess(title: string, message: string): void {
    this.toastrService.success(message, title ,{
        enableHtml: true,
        closeButton: true,
        timeOut: 5000,
    })
    //toastr['success'](message, title);
  }
  toastinfo(title: string, message: string): void {
    this.toastrService.info(message, title,{
      enableHtml: true,
      closeButton: true,
      timeOut: 5000,
    })
   // toastr['info'](message, title);
  }
  toastwarning(title: string, message: string): void {
    this.toastrService.warning(message, title,{
      enableHtml: true,
      closeButton: true,
      timeOut: 5000,
    })
    //toastr['warning'](message, title);
  }
  toasterror(title: string, message: string): void {
    this.toastrService.error(message, title,{
      enableHtml: true,
      closeButton: true,
      timeOut: 5000,
    })
   // toastr['error'](message, title);
  }

  async swalConfirm(
    title: string,
    text: string,
    html?: string
  ): Promise<boolean> {
    let confirm = false;
    if (!html) {
      await Swal.fire({
        title,
        text,
        showCancelButton: true,
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ยกเลิก',
      }).then((result) => {
        confirm = result.value;
      });
      if (confirm) {
        return true;
      } else {
        return false;
      }
    } else {
      await Swal.fire({
        title,
        text,
        html,
        showCancelButton: true,
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ยกเลิก',
      }).then((result) => {
        confirm = result.value;
      });
      if (confirm) {
        return true;
      } else {
        return false;
      }
    }
  }

  async swalConfirmCustomResponse(
    title: string,
    text: string,
    html: string,
    confirmButton: string,
    cancelButton: string,
  ): Promise<boolean> {
    let confirm = false;
    if (!html) {
      await Swal.fire({
        title,
        text,
        showCancelButton: true,
        confirmButtonText: confirmButton,
        cancelButtonText: cancelButton,
      }).then((result) => {
        confirm = result.value;
      });
      if (confirm) {
        return true;
      } else {
        return false;
      }
    } else {
      await Swal.fire({
        title,
        text,
        html,
        showCancelButton: true,
        confirmButtonText: confirmButton,
        cancelButtonText: cancelButton,
      }).then((result) => {
        confirm = result.value;
      });
      if (confirm) {
        return true;
      } else {
        return false;
      }
    }
  }

  async deleteMasterrow(title, message): Promise<void> {
    await Swal.fire({
      title,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });
  }

  swalDeleteConfirm(
    title: string = 'ต้องการลบข้อมูลหรือไม่ ?',
    msg: string = '',
    msgSuccess: string = 'ลบข้อมูลเรียบร้อย',
    confirmBtn: string = 'ใช่',
    cancelBtn: string = 'ยกเลิก'
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await Swal.fire({
        title,
        text: msg,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: confirmBtn,
        cancelButtonText: cancelBtn,
      }).then((result) => {
        if (result.value) {
          // Swal.fire(msgSuccess, "", "success");
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  async swalConfirmPassword(
    title: string,
    text: string,
    html?: string
  ): Promise<boolean> {
    let confirm = false;
    if (!html) {
      await Swal.fire({
        title,
        text,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        showCancelButton: false,
        confirmButtonText: 'ใช่',
      }).then((result) => {
        confirm = result.value;
      });
      if (confirm) {
        return true;
      } else {
        return false;
      }
    } else {
      await Swal.fire({
        title,
        text,
        html,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        showCancelButton: false,
        confirmButtonText: 'ใช่',
      }).then((result) => {
        confirm = result.value;
      });
      if (confirm) {
        return true;
      } else {
        return false;
      }
    }
  }
  swalWarning(title:string,text:string){
    Swal.fire({
      icon: 'warning',
      title: title,
      text: text
    })
  }
  notiCarrier(truckBookingId:number, shipmentId:number,itemId:number, remark:string):Promise<string[]>{
    const payload = {remark:remark}
    return new Promise((resolve, reject) => {
      this._apiService
      .post(`/Notification/NotiCarrier/${itemId}/${truckBookingId}/${shipmentId}`, payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
