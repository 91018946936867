import { Injectable } from '@angular/core';
import { BookingAPIPath, ClientAPIPath, MasterAPIPath, ShipmentApi } from '@app/core/_Constants/apiConstants';
import { ProvinceDto } from '@app/core/_Models/MasterModel/ProvinceModel';
import { TMSAPIResponse } from '@app/core/_Models/TMSAPIResponse';
import { AuthenticationService, HttpService, NotificationService, PageloadingService } from '@app/core/_Services';
import { BasicBookingDraftReesponse } from '@app/modules/techx-basic-booking/models/booking-model';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TruckBookingAssignDto } from '../../models/2022/booking-assign-model';
import { Booking2022FilterModel, Shipment360TruckFilterModel } from '../../models/2022/booking-filter-model';
import { BookingSpotRouteModel } from '../../models/2022/booking-spot-route-model';
import { BookingExpense, CarrierProposeData, ProposeTruckType } from '../../models/2022/carrier-propose-model';
import { Shipment360CarrierResponse } from '../../models/2022/shipment-360truck-model';
import { AddTruckDriverRequest, checkMultiDriverAllowedModel, ShipmentCountResponse, ShipmentDriverIdModel, ShipmentDriverModel, ShipmentResponse, ShipmentSettingModel, ShipmentTotalDto } from '../../models/2022/shipment-model';
import { CancelConfirmPriceDto, ConfirmPriceDto } from '../../models/2022/confirm-price-modal';
import { BaseResponse } from '@app/core/_Models/BaseResponse';


@Injectable({
  providedIn: 'root'
})
export class BookingV2022Service {
  destroy$: Subject<boolean> = new Subject<boolean>();
  provinceListOnChange: BehaviorSubject<ProvinceDto[]>;
  shipmentOnChange: BehaviorSubject<ShipmentResponse>;
  shipment360TruckOnChange: BehaviorSubject<Shipment360CarrierResponse>;
  shipmentTotalStatus: BehaviorSubject<ShipmentTotalDto>;
  shipmentDriverListOnChange: BehaviorSubject<ShipmentDriverModel[]>;
  checkMultiDriverAllowedOnChange: BehaviorSubject<checkMultiDriverAllowedModel>;
  



  bookingDraftOnChange: BehaviorSubject<BasicBookingDraftReesponse>;
  driverIdOnChange: BehaviorSubject<number> = new BehaviorSubject(0);
  shipmentCountOnChange: BehaviorSubject<ShipmentCountResponse>;

  constructor(
    private httpService: HttpService,
    private auth: AuthenticationService,
    // private pageLoad: PageloadingService,
    private notiService: NotificationService,
    ) {
    this.provinceListOnChange = new BehaviorSubject(new Array<ProvinceDto>());
    this.shipmentOnChange = new BehaviorSubject(null);
    this.shipmentTotalStatus = new BehaviorSubject(null);
    this.shipmentDriverListOnChange = new BehaviorSubject(new Array<ShipmentDriverModel>());
    this.bookingDraftOnChange = new BehaviorSubject(null);
    this.shipment360TruckOnChange = new BehaviorSubject(null);
    this.shipmentCountOnChange = new BehaviorSubject(null);
    this.checkMultiDriverAllowedOnChange = new BehaviorSubject(null);
  }
  public displayTruckBookingNo(truckBookingNo: string): string {
    if (truckBookingNo)
      return truckBookingNo.replace("-"," คันที่ ")
    return truckBookingNo
  }

  public displayShipmentNo(shipmentNoDisplay: string): string {
    if (shipmentNoDisplay)
      return shipmentNoDisplay.replace("-"," คันที่ ")
    return shipmentNoDisplay
  }

  getProvinceList(): void {
    this.httpService.get(MasterAPIPath.GetProvinceList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: TMSAPIResponse) => {
        this.provinceListOnChange.next(res.data)
      })
  }

  getShipmentList(dataReq: Booking2022FilterModel): Promise<ShipmentResponse> {
    //this.pageLoad.show()
    dataReq.roleId = this.auth.currentUserValue.Role.roleID;
    dataReq.isTruckPlus  = this.auth.isTruckPlusUser();
    return new Promise((resolve, reject) => {
      this.httpService.post(ShipmentApi.getShipmentBasic, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          //this.pageLoad.hide()
          // console.log('res', res);
          this.shipmentOnChange.next(res);
          resolve(res)
        })
    })
  }

  getShipmenCount(clientId): Promise<ShipmentCountResponse> {
    //this.pageLoad.show()
    return new Promise((resolve, reject) => {
      this.httpService.get(ShipmentApi.GetShipmentCount+'/'+clientId,)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          //this.pageLoad.hide()
           console.log('res', res);
           console.log('resdata', res.data);
          this.shipmentCountOnChange.next(res.data);
          resolve(res.data)
        })
    })
  }
  getShipment360TruckList(dataReq: Shipment360TruckFilterModel): Promise<Shipment360CarrierResponse> {
    //this.pageLoad.show()
    return new Promise((resolve, reject) => {
      this.httpService.post(ShipmentApi.Shipments360TruckCarrier, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          //this.pageLoad.hide()
          // console.log('res', res);
          this.shipment360TruckOnChange.next(res);
          resolve(res)
        })
    })
  }
  getShipmentTotalStatus(): Promise<ShipmentTotalDto> {
    //this.pageLoad.show()
    var roleId = this.auth.currentUserValue.Role.roleID;
    var isTruckPlus = this.auth.isTruckPlusUser();
    return new Promise((resolve, reject) => {
      this.httpService.get(`${ShipmentApi.getShipmentTotalStatus}?clientId=${this.auth.currentUserValue.ClientID}&roleId=${roleId}&roleId=${roleId}&IsTruckPlus=${isTruckPlus}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          //this.pageLoad.hide()
          // console.log('res', res);
          this.shipmentTotalStatus.next(res);
          resolve(res)
        })
    })
  }

  confirmPrice(data: ConfirmPriceDto): Promise<BaseResponse<void>> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post(ShipmentApi.ComfirmPrice, data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  cancelConfirmPrice(data: CancelConfirmPriceDto): Promise<BaseResponse<void>> {
    return new Promise((resolve, reject) => {
      this.httpService
        .post(ShipmentApi.CancelConfirmPrice, data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getDriverWithShipment(dataReq: any): Promise<ShipmentDriverModel[]> {
    //this.pageLoad.show()
    return new Promise((resolve, reject) => {
      this.httpService.post(ClientAPIPath.GetDriverWithShipment, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          //this.pageLoad.hide()
          // console.log('getCompanyTailWithShipment', res);
          this.shipmentDriverListOnChange.next(res.data);
          resolve(res.data);
        });
    })
  }

  saveShipmentTruckAndDriver(dataReq: AddTruckDriverRequest): Promise<any> {
    //this.pageLoad.show()
    return new Promise((resolve, reject) => {
      this.httpService.post(ShipmentApi.saveShipmentTruckAndDriver, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          //this.pageLoad.hide()
          // console.log('saveShipmentTruckAndDriver', res);
          // this.shipmentDriverListOnChange.next(res);
          this.getShipmenCount(this.auth.currentUserValue.ClientID);
          resolve(res);
        });
    })
  }

  getBookingDraftList(dataReq: Booking2022FilterModel):Promise<BasicBookingDraftReesponse> {
    //this.pageLoad.show()
    return new Promise((resolve, reject) => {
      this.httpService.post(BookingAPIPath.GetBookingDraftList, dataReq)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          //this.pageLoad.hide()
          // console.log('res', res);
          this.bookingDraftOnChange.next(res?.data);
          resolve(res?.data)
        })
    })
  }

  getShipmentSetting(
    shipmentID: number
  ): Promise<BaseResponse<ShipmentSettingModel>> {
    return new Promise((resolve, reject) => {
      this.httpService
        .get(`${ShipmentApi.getShipmentSetting}/${shipmentID}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getBookingAssignByBookingId(ClientID: number, bookingID: number): Observable<TruckBookingAssignDto> {
    return this.httpService.get(
      `${BookingAPIPath.GetBookingAssignByBookingId}/${ClientID}/${bookingID}/`
    );
  }

  saveSpotRouteWithBooking(data: BookingSpotRouteModel): Promise<TMSAPIResponse> {
    //this.pageLoad.show()
    data.userId = this.auth.currentUserValue.tstS360_UserID;
    return new Promise((resolve, reject) => {
      this.httpService.post(`/Routes/favoriteRoute`, data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          //this.pageLoad.hide()
          if(res.isSuccess) {
            this.notiService.toastsuccess("บันทึกเส้นทาง SPOT ROUTES","สำเร็จ")
          } else {
            this.notiService.toasterror("SPOT ROUTES error", res.message)
          }
          resolve(res)
        })
    })
  }

  getShipmentX360(shipmentId: number): Observable<any> {
    return this.httpService.get(
      `${ShipmentApi.getShipmentX360}/${shipmentId}`
    );
  }

  getCarrierProposeTmsWorkById(companyId: number, refId: number,shipmentId: number): Promise<TMSAPIResponse> {

    //this.pageLoad.show()
    return new Promise((resolve, reject) => {
      this.httpService.get(
        `${ClientAPIPath.GetCarrierProposeTmsWorkById}/${companyId}/${refId}/${shipmentId}`
      ).subscribe((response: TMSAPIResponse) => {
        if (response.isSuccess) {
          var dataWait = response.data.find(f=>f.truckBookingId == refId);
          var CarrierProposeDto = new CarrierProposeData();
          CarrierProposeDto.is360Work = false;
          CarrierProposeDto.userId = this.auth.currentUserValue.tstS360_UserID;
          CarrierProposeDto.carrierCompanyId = companyId;

          if ((dataWait.originLabor + dataWait.destinationLabor) > 0)
          {
              CarrierProposeDto.needLabor = true;
          }

          CarrierProposeDto.shipperBookingId = dataWait.truckBookingId;
          CarrierProposeDto.shipperBookingNo = dataWait.truckBookingNo;
          if (dataWait.isBidPrice)
          {
              CarrierProposeDto.requestCategoryID = 212;
          }
          else
          {
              CarrierProposeDto.requestCategoryID = 211;
          }

          CarrierProposeDto.isBidPrice = dataWait.isBidPrice;
          if (dataWait?.assignmentExpenses?.length > 0)
          {
              CarrierProposeDto.unitPriceId = dataWait.assignmentExpenses[0]?.priceUnitId;
          }
          CarrierProposeDto.bookingExpenseDtos = [];
          let expenseDto513 = new BookingExpense();

          var exp513 = dataWait.assignmentExpenses.find(f => f.expenseTypeId == 513);
          let expenseUnitName = null;

          if (exp513 != null)
          {
              CarrierProposeDto.unitPriceId = exp513.expenseTypeId;
              CarrierProposeDto.unitPriceName = exp513.priceUnitName;

              expenseUnitName = exp513.priceUnitName;
              expenseDto513.expenseTypeName = `ค่าขนส่ง (ต่อ${exp513.priceUnitName})`;
              expenseDto513.quantity = dataWait.noOfTruck;
              expenseDto513.pricePerUnit = (exp513.price / dataWait.noOfTruck);
              expenseDto513.expenseTypeId = exp513.expenseTypeId;
              CarrierProposeDto.deliveryTextDisplay = expenseDto513.expenseTypeName;

              CarrierProposeDto.bookingExpenseDtos.push(expenseDto513);
          }

          let expenseDto521 = new BookingExpense();
          var exp521 = dataWait.assignmentExpenses.find(f => f.expenseTypeId == 521);
          if (exp521 != null)
          {
              expenseDto521.expenseTypeName = `ค่าแรงงาน (ต่อ${expenseUnitName})`;
              expenseDto521.quantity = 1;
              expenseDto521.pricePerUnit = exp521.price;
              expenseDto521.expenseTypeId = exp521.expenseTypeId;
              CarrierProposeDto.bookingExpenseDtos.push(expenseDto521);

              CarrierProposeDto.deliveryLaborTextDisplay = expenseDto521.expenseTypeName;
          }

          CarrierProposeDto.totalTargetPrice =
            CarrierProposeDto.bookingExpenseDtos.reduce((accumulator, object) => {
              return accumulator + (object.pricePerUnit * object.quantity);
            }, 0);
          CarrierProposeDto.proposeTruckTypes = dataWait.truckType;
          //   const a = {
          //     noOfTruck = 0,
          //     truckTypeId = s.truckTypeId,
          //     truckTypeName = s.truckTypeName
          //   // }

          //   return a
          // });

          // .Select(s => new CarrierProposeTruckType()
          // {
          //     NoOfTruck = 0,
          //     TruckTypeId = s.TruckTypeId,
          //     TruckTypeName = s.TruckTypeName
          // }).ToList();
          CarrierProposeDto.assignmentId = dataWait.assignmentId;

          if (dataWait.isBidPrice)
          {
              CarrierProposeDto.deliveryPricePerUnitPrice = dataWait.pricePerTruck;
          }
          else
          {
              CarrierProposeDto.deliveryPricePerUnitPrice = expenseDto513.pricePerUnit;
          }

          CarrierProposeDto.laborPricePerUnitPrice = dataWait.priceLaborPerTruck;
          response.data = CarrierProposeDto;

          //this.pageLoad.hide()
          resolve(response);
        } else {
          //this.pageLoad.hide()
          resolve({
            isSuccess: false,
            data: null,
          } as TMSAPIResponse)
        }
      })
    })
  }

  carrierProposeUpdate(data: CarrierProposeData): Promise<TMSAPIResponse> {
    //this.pageLoad.show()
    return new Promise((resolve, reject) => {
      this.httpService.post(ClientAPIPath.CreateCarrierAssignment, data)
      .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          // debugger;
          //this.pageLoad.hide()
          if(res.isSuccess) {
            this.notiService.toastsuccess("บันทึกสำเร็จ","")
          } else {
            this.notiService.toasterror("ผิดพลาด", res.message)
          }
          resolve(res)
        })

    })
  }
  carrierRejectShipment(data: any): Promise<TMSAPIResponse> {
    //this.pageLoad.show()
    return new Promise((resolve, reject) => {
      this.httpService.post(ClientAPIPath.CarrierRejectAssignment, data)
      .pipe(takeUntil(this.destroy$))
        .subscribe((res: TMSAPIResponse) => {
          // debugger;
          //this.pageLoad.hide()
          if(res.isSuccess) {
            this.notiService.toastsuccess("บันทึกสำเร็จ","")
          } else {
            this.notiService.toasterror("ผิดพลาด", res.message)
          }
          resolve(res)
        })
    })
  }

  checkMultiDriverAllowed(data: object): Promise<TMSAPIResponse> {
    return new Promise((resolve, reject) => {
      this.httpService.post(ShipmentApi.checkMultiDriverAllowed, data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res: TMSAPIResponse) => {
              this.checkMultiDriverAllowedOnChange.next(res.data);
              resolve(res);
          },
          (error) => {
            reject(error)
          }
        )
    })
  }
}
