<app-dynamic-breadcrumb></app-dynamic-breadcrumb>
<div class="panel-tag border-top-right-radius-0 border-bottom-right-radius-0">
    <h5 class="fw-500">TIP : อย่าลืมเข้ามาอัพเดทเอกสารคนขับให้พร้อมเสมอ</h5>
</div>

<div class="card m-auto border">
    <div class="card-header py-2">
        <div class="row">
            <div class="col-12 col-sm-3">
                <div>
                    <i class="fal fa-user-alt color-success-100 fw-900 mr-1"></i>
                    <span class="fs-sm fw-500">รายละเอียด</span>
                </div>
            </div>
            <div class="col-12 col-sm-9">
                <div *ngIf="fleetDriver.shipmentStatusName">
                    <span class="fs-sm">
                        สถานะ : <span class="badge badge-success badge-pill">{{fleetDriver.shipmentStatusName}}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body row">
        <div class="col-12 col-sm-3">
            <div class="row">
                <img src="{{fleetDriver?.photoURL || '/assets/img/demo/avatars/avatar-m.png'}}" class="profile-image rounded-circle">
                <div class="ml-2">
                    <div class="help-block text-muted">ชื่อ - นามสกุล</div>
                    <div>
                        <label>{{fleetDriver.firstName}} {{fleetDriver.lastName}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-3">
            <div class="help-block text-muted">เบอร์โทร (Username)</div>
            <div>
                <label >{{fleetDriver.mobileNo | mask: "000-000-0009"}}</label>
            </div>
        </div>
        <div class="col-12 col-sm-3">
            <div class="help-block text-muted">fleet : {{fleetDriver.companyFleetNo}}
            </div>
            <div>
                <label>{{fleetDriver.fleetName}}</label>
            </div>
        </div>
        <div class="col-12 col-sm-3">
            <div class="row">
                <div class="col-6 p-1">
                    <button class="btn btn-sm btn-success btn-block text-truncate"
                        (click)="openmodalsharedriverdetail()">แชร์ข้อมูล</button>
                </div>
                <div class="col-6 p-1">
                    <button class="btn btn-sm btn-dark btn-block text-truncate"
                        (click)="buttonPrintClick()">พิมพ์</button>
                </div>
            </div>


        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 col-sm-4 col-md-4 mt-3" *ngIf="permission130100.canRead">
        <div class="card">
            <!-- <div class="card-header py-2">
                ข้อมูลคนขับ
            </div> -->
            <div class="card-header py-2 d-flex align-items-center flex-wrap">
                <!-- we wrap header title inside a div tag with utility padding -->
                <div class="card-title">
                    <i class="fal fa-user-alt color-success-100 fw-900 mr-1"></i>
                    <span class="fs-sm fw-500">ข้อมูลคนขับ</span>
                </div>
                <span class="badge badge-success badge-pill ml-auto">{{fleetDriver.shipmentStatusName}}</span>
            </div>
            <div class="card-body">

                <div class="row text-center">
                    <div class="col-6">
                        <!-- <img *ngIf="fleetDriver.photoURL" src="{{fleetDriver.photoURL}}"
                            class="h-auto w-75 rounded-corners-gradient-borders"> -->
                            <app-profile-upload [setFleetDriver]="fleetDriver" (refresh)="refreshEvent($event)"></app-profile-upload>
                    </div>
                    <div class="col-6">
                        <qrcode [qrdata]="fleetDriver.driverURL" [errorCorrectionLevel]="'M'" [width]="100"
                            cssClass="img-fluid" [elementType]="'img'" [allowEmptyString]="true"></qrcode>
                    </div>
                    <!-- <div class="col-6 mt-3">
                        <button
                            class="btn btn-success btn-block btn-sm waves-effect waves-themed text-truncate">แก้ไขรูปโปรไฟล์</button>
                    </div> -->
                    <!-- <div class="col-6 mt-3">
                        <button
                            class="btn btn-success btn-block btn-sm waves-effect waves-themed text-truncate">QRCODE</button>
                    </div> -->
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="help-block text-muted">ชื่อ - นามสกุล
                        </div>
                        <div>
                            <label>{{fleetDriver?.firstName}} {{fleetDriver?.lastName}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="help-block text-muted">เบอร์โทร (ใช้เป็น Username ในการเข้าสู่ระบบ)
                        </div>
                        <div>
                            <label>{{fleetDriver.mobileNo | mask: "000-000-0009"}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="help-block text-muted">เลขบัตรประชาชน</div>
                        <div>
                            <label>{{fleetDriver.idcard || '-'}}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="help-block text-muted">
                            เดือน/ปีเกิด
                        </div>
                        <div>
                            <label *ngIf="fleetDriver.dateOfBirth">{{fleetDriver.dateOfBirth|thaidate:'short'}} ({{fleetDriver.yearOld}} ปี)</label>
                            <label *ngIf="!fleetDriver.dateOfBirth">-</label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="help-block text-muted">รูปบัตรประชาชน</div>
                        <img src="{{fleetDriver.userIdCardUrl}}" class="h-auto w-50 rounded">
                    </div>
                    <div class="col-6">
                        <div class="help-block text-muted">รูปใบขับขี่</div>
                        <img src="{{fleetDriver.userDriverLicenceUrl}}" class="h-auto w-50 rounded">
                    </div>
                    <div class="col-12">
                        <!-- <div class="help-block text-muted">รูปใบสมัครงาน / รูปอื่น ๆ</div> -->
                        <!-- <img src="{{driverDetail.registerAndOtherPhotoURL}}" class="h-auto w-35 rounded"> -->
                    </div>
                </div>

            </div>
            <div class="card-footer-custom">
                <button  *ngIf="permission130100.canEdit"
                    class="btn btn-sm btn-success waves-effect waves-themed btn-footer btn-block" (click)="openmodaleditdriverform(driver_id)">แก้ไขข้อมูล</button>
            </div>
        </div>

    </div>

    <div class="col-12 col-sm-4 col-md-4 mt-3"  *ngIf="permission130200.canRead">
        <!-- <div class="card">
            <div class="card-header py-2">
                <div class="card-title">ประสิทธิภาพ</div>
            </div>
            <div class="card-body row">
                <div class="col-12">
                    <div class="help-block text-muted">แสดงผลประสิทธิภาพการทำงาน
                    </div>
                    <div>
                        <ng-select style="width: calc(100%x)" [(ngModel)]="performancePerMonthSelect" clearable
                            (change)="test(driverDetail.performancePerMonth[performancePerMonthSelect])">
                            <ng-option *ngFor="let pfpm of driverDetail.performancePerMonth;let i = index" [value]="i">
                                {{pfpm.month_year}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12 mt-1">
                    <div class="help-block text-muted">ระยะทางรวม (กิโลเมตร)</div>
                    <div>
                        <label>{{driverDetail.distance_sum|number:'1.2'}} กม.</label>
                    </div>
                </div>

                <div class="col-4">
                    <div class="help-block text-muted">รายได้รวม</div>
                    <div class="border-custom rounded bg-success-custom font-custom-div p-2 text-center">
                        {{performancePerMonth.income_per_month|number:'1.2'}}</div>
                </div>
                <div class="col-4">
                    <div class="help-block text-muted">รายได้ต่อวัน</div>
                    <div class="border-custom rounded bg-success-custom font-custom-div p-2 text-center">
                        {{performancePerMonth.income_per_day|number:'1.2'}}</div>
                </div>
                <div class="col-4">
                    <div class="help-block text-muted">รายได้ต่อกิโล</div>
                    <div class="border-custom rounded bg-success-custom font-custom-div p-2 text-center">
                        {{performancePerMonth.income_per_km|number:'1.2'}}</div>
                </div>

                <div class="col-4">
                    <div class="help-block text-muted">เที่ยวรวม</div>
                    <div class="border-custom rounded bg-success-custom font-custom-div p-2 text-center">
                        {{performancePerMonth.roundTrip_per_month|number:'1.0'}} เที่ยว</div>
                </div>
                <div class="col-4">
                    <div class="help-block text-muted">เที่ยวต่อวัน</div>
                    <div class="border-custom rounded bg-success-custom font-custom-div p-2 text-center">
                        {{performancePerMonth.roundTrip_per_day|number:'1.0'}} เที่ยว</div>
                </div>
                <div class="col-4">
                    <div class="help-block text-muted">กิโลเมตร/เที่ยว</div>
                    <div class="border-custom rounded bg-success-custom font-custom-div p-2 text-center">
                        {{performancePerMonth.roundTrip_per_km|number:'1.0'}} เที่ยว</div>
                </div>


            </div>
        </div> -->

        <!-- mt3 -->
        <div class="card" >
            <div class="card-header py-2">
                <!-- we wrap header title inside a div tag with utility padding -->
                <div class="card-title">
                    <i class="fal fa-chart-bar color-success-100 fw-900 mr-1"></i>
                    <span class="fs-sm fw-500">อัตราค่าจ้าง (เงินเดือน / ค่าเที่ยว)</span>
                </div>
            </div>
            <div *ngIf="userSalaries.length > 0" class="card-body row">
                <div class="col-6">
                    <div class="help-block text-muted">อัตราเงินเดือน</div>
                    <label *ngIf="userSalaries.length > 0">{{userSalaries[0].salary|number:'1.0'}} บาท/เดือน</label>
                </div>
                <div class="col-6">
                    <div class="help-block text-muted">อัตราค่าเที่ยว</div>
                    <label *ngIf="userSalaries.length > 0">{{userSalaries[0].tripWage|number:'1.0'}} บาท/เที่ยว</label>
                </div>
                <div class="col-6">
                    <div class="help-block text-muted">Valid since</div>
                    <label *ngIf="userSalaries.length > 0">{{userSalaries[0].startDate|thaidate:"short"}}</label>
                </div>

                <div class="col-12 border-faded border-left-0 border-right-0 border-bottom-0 mb-2"></div>

                <div class="col-12">
                    <div class="row">
                        <div class="help-block text-muted col-6">ประวัติปรับค่าจ้าง</div>
                        <div class="help-block text-muted col-6">เงินเดือน/ค่าเที่ยว</div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="row" *ngFor="let userSalary of userSalaries|slice:1:3">
                        <label class="col-6">{{userSalary.startDate|thaidate:"short"}}</label>
                        <label class="col-6">{{userSalary.salary|number:'1.0'}}/{{userSalary.tripWage|number:'1.0'}}
                            บาท</label>
                    </div>
                </div>
            </div>

            <div *ngIf="userSalaries.length <= 0" class="card-body row">
                <div class="col-12 text-center">
                    <span class="text-muted">ไม่มีข้อมูล</span>
                </div>
            </div>

            <div class="card-footer-custom">
                <button class="btn btn-sm btn-success waves-effect waves-themed btn-footer btn btn-block"
                    (click)="openmodalsalaryratedetail()"  *ngIf="permission130200.canEdit">ดูข้อมูล/แก้ไขข้อมูล</button>
            </div>

        </div>

    </div>

    <div class="col-12 col-sm-4 col-md-4 mt-3">
        <div class="card"   *ngIf="permission130100.canRead">
            <div class="card-header py-2">
                <div class="card-title">
                    <i class="fal fa-star color-success-100 fw-900 mr-1"></i>
                    <span class="fs-sm fw-500">ประวัติการอบรม</span>
                </div>
                <!-- we wrap header title inside a div tag with utility padding -->
            </div>
            <div *ngIf="userTrainings.length > 0" class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="help-block text-muted col-4">วันที่</div>
                            <div class="help-block text-muted col-8">หัวข้อการอบรม</div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="row" *ngFor="let userTraining of userTrainings| slice:0:2;">
                            <label class="col-4">{{userTraining.date|thaidate:'short'}}</label>
                            <label class="col-8">{{userTraining.title}}</label>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-12 mt-2">
                    <a class="text-success" (click)="openmodaltraininghistoryform()"><i class="fas fa-plus-square mr-1"></i><u>เพิ่มประวัติการอบรม</u></a>
                </div> -->
            </div>

            <div *ngIf="userTrainings.length <= 0" class="card-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 text-center">
                            <span class="text-muted">ไม่มีข้อมูล</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer-custom">
                <button *ngIf="permission130100.canEdit" class="btn btn-sm btn-success waves-effect waves-themed btn-footer btn btn-block"
                    (click)="openmodaltraininghistorydetail()">ดูข้อมูล/แก้ไขข้อมูล</button>
            </div>
        </div>
        <div class="card mt-3"  *ngIf="permission130200.canRead">
            <div class="card-header py-2">
                <div class="card-title">
                    <i class="fal fa-wallet color-success-100 fw-900 mr-1"></i>
                    <span class="fs-sm fw-500">บัญชี / วอลเล็ตเงินเดือน</span>
                </div>
            </div>
            <div *ngIf="userBookbank" class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="help-block text-muted">สถานะบัญชี</div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                                <label *ngIf="userBookbank.defaultFlag" class="text-success"><i
                                        class="fas fa-circle mr-1"></i>ตั้งเป็นบัญชีหลัก</label>
                                <!-- <label *ngIf="!userBookbank.defaultFlag" class="text-muted"><i
                                        class="fas fa-circle mr-1"></i>บัญชีสำรอง</label> -->
                                <label *ngIf="!userBookbank.defaultFlag" class="text-muted">-</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6">
                                <div class="help-block text-muted">ธนาคาร</div>
                                <!-- <img src="{{userBookbank.bankIcon}}" class="h-auto w-15 mr-1"> -->
                                <label *ngIf="userBookbank.bankName">{{userBookbank.bankName}}</label>
                                <label *ngIf="!userBookbank.bankName">-</label>
                            </div>
                            <div class="col-6">
                                <div class="help-block text-muted">เลขบัญชี</div>
                                <label *ngIf="userBookbank.accountNo">{{userBookbank.accountNo}}</label>
                                <label *ngIf="!userBookbank.accountNo">-</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6">
                                <div class="help-block text-muted">ชื่อบัญชี</div>
                                <label *ngIf="userBookbank.accountName">{{userBookbank.accountName}}</label>
                                <label *ngIf="!userBookbank.accountName">-</label>
                            </div>
                            <div class="col-6">
                                <div class="help-block text-muted">สาขา</div>
                                <label *ngIf="userBookbank.bankBranch">{{userBookbank.bankBranch}}</label>
                                <label *ngIf="!userBookbank.bankBranch">-</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                                <div class="help-block text-muted">รูปภาพหน้าบุ้คแบงค์</div>
                                <img *ngIf="userBookbank.filePath" src="{{userBookbank.filePath}}"
                                    class="h-auto w-35 mr-1">
                                <label *ngIf="!userBookbank.filePath">-</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!userBookbank" class="card-body">
                <div class="row">
                    <div class="col-12 text-center">
                        <span class="text-muted">ไม่มีข้อมูล</span>
                    </div>
                </div>
            </div>

            <div class="card-footer-custom">
                <button class="btn btn-sm btn-success waves-effect waves-themed btn-footer btn-block"
                    (click)="openmodalaccountform()"  *ngIf=" (permission130200.canPublish && accountAction == 'CREATE') || (permission130200.canEdit && accountAction == 'EDIT') ">{{accountHeaderText}}</button>
            </div>
        </div>
    </div>
</div>

<div class="card border mt-3"  *ngIf="permission130200.canRead">
    <div class="card-header py-2">
        <div class="card-title">
            <i class="fal fa-chart-area color-success-100 fw-900 mr-1"></i>
            <span class="fs-sm fw-500">ประวัติค่าจ้าง</span>
        </div>
    </div>
    <div *ngIf="monthSelected" class="card-body row">
        <div class="col-12">
            <div class="row">
                <div class="col-4">
                    <div class="help-block text-muted">สรุปประจำเดือน</div>
                    <ng-select style="width: calc(100%)" [items]="month" bindLabel="name" bindValue="id"
                        [(ngModel)]="monthSelected" (change)="onselectedMonth()" clearable>
                    </ng-select>
                </div>
                <div class="col-4">
                    <div class="help-block text-muted">เงินเดือนเดือนนี้</div>
                    <div class="border-custom rounded font-custom-div p-2 text-center text-amount-custom fw-700">
                        <span *ngIf="userStatementMonth.monthAmount">{{userStatementMonth.monthAmount|number:'1.0'}}
                            บาท</span>
                        <span *ngIf="!userStatementMonth.monthAmount">-</span>
                    </div>
                </div>
                <div class="col-4">
                    <div class="help-block text-muted">ค่าเที่ยวเดือนนี้</div>
                    <div class="border-custom rounded font-custom-div p-2 text-center text-amount-custom fw-700">
                        <span *ngIf="userStatementMonth.tripWageAmount">
                            {{userStatementMonth.tripWageAmount|number:'1.0'}} บาท
                        </span>
                        <span *ngIf="!userStatementMonth.tripWageAmount">
                            -
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 mt-3">
            <div class="row">
                <div class="col-4">
                    <div class="help-block text-muted">Order No.</div>
                </div>
                <div class="col-4">
                    <div class="help-block text-muted">หัวข้อการชำระ</div>
                </div>
                <div class="col-4">
                    <div class="help-block text-muted">ยอดเงิน</div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div *ngFor="let userStatementDate of userStatementDates" class="row">

                <div class="col-12">
                    <label class="fw-900">{{userStatementDate.statementDate|thaidate:'short'}}</label>
                </div>

                <div class="col-12">
                    <div *ngFor="let userStatementDto of userStatementDate.userStatementDtos" class="row">
                        <div class="col-4">
                            <label>{{userStatementDto.statementNo}}</label>
                        </div>
                        <div class="col-4 text-center">
                            <label class="border-custom font-custom-div p-1"
                                style="border-radius:15px;width: 5rem;">{{userStatementDto.m_StatementType_Name}}</label>
                        </div>
                        <div class="col-4 text-right">
                            <label class="fw-700"
                                [ngClass]="{'text-success':userStatementDto.amount >=0,'text-danger':userStatementDto.amount <0}">
                                <span *ngIf="userStatementDto.amount >= 0">+</span>
                                <span *ngIf="userStatementDto.amount < 0"></span>
                                {{userStatementDto.amount|number:'1.2'}} บาท
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="!monthSelected" class="card-body row">
        <div class="col-12 text-center">
            <span class="text-muted">ไม่มีข้อมูล</span>
        </div>
    </div>

    <div class="card-footer-custom">
        <div class="row">
            <div class="col-12">
                <app-modal-payment-history [driver_id]="driver_id"></app-modal-payment-history>
            </div>
        </div>
    </div>
</div>

<app-modal-pre-trip-driver [items]="dataPreTrip"></app-modal-pre-trip-driver>

<app-modal-training-history-form #modaltraininghistoryform></app-modal-training-history-form>
<app-modal-training-history-detail #modaltraininghistorydetail [userTrainings]="userTrainings" driver_id={{driver_id}}
    (closeTrainingForm_event)="getUserTraining()" (btnOpenModal)="openmodaltraininghistorydetail()">
</app-modal-training-history-detail>
<app-modal-salary-rate-detail #modalsalaryratedetail [userSalaries]="userSalaries" (onModalClose)="getUserSalary()"
    driver_id={{driver_id}}>
</app-modal-salary-rate-detail>
<app-modal-account-form #modalaccountform_create action="CREATE" modalTitle="เพิ่มบัญชีธนาคาร"
    (btnother_click)="getUserBookbank()" driver_id={{driver_id}}>
</app-modal-account-form>
<app-modal-account-form #modalaccountform_edit action="EDIT" modalTitle="แก้ไขข้อมูลบัญชีธนาคาร"
    [userBookbank]="userBookbank" (btnother_click)="getUserBookbank()" driver_id={{driver_id}}>
</app-modal-account-form>
<app-modal-share-driver-detail #modalsharedriverdetail [driverDetailSharedCheckList]="driverDetailSharedCheckList">
</app-modal-share-driver-detail>
<app-modal-driver-print #modaldriverprint (selectedDataPrintEvent)="selectedDataPrintEvent($event)">
</app-modal-driver-print>
<app-driver-gen-pdf-template id="truck-gen-pdf" [ngClass]="{'d-none':hiddenTemplatePrint}"
    [topicList]="selectedDataPrintList" [driverDetail]="driverDetail">
</app-driver-gen-pdf-template>

<app-modal-edit-driver-form #modaleditdriverform [IsDriver]="isDriver" [IsEdit]="true" [EditData]="userEditData"
  [IsView]="isViewUser" (AfterSubmit)="AfterFormSubmit($event)">
</app-modal-edit-driver-form>
