import { Directive, ElementRef, Input, OnDestroy, AfterViewInit } from '@angular/core';
import tippy, { Instance, Props } from 'tippy.js';

@Directive({
  selector: '[appTippy]',
})

export class TippyDirective implements OnDestroy, AfterViewInit {
  @Input('appTippy') tippyContent!: string | HTMLElement;
  @Input() tippyOptions?: Partial<Props>;

  private tippyInstance: Instance[] | Instance | null = null;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.tippyInstance = tippy(this.el.nativeElement, {
      content: this.tippyContent,
      allowHTML: true,
      theme: 'light',
      maxWidth: 300,
      ...this.tippyOptions,
    });
  }

  ngOnDestroy() {
    if (Array.isArray(this.tippyInstance)) {
      this.tippyInstance.forEach((instance) => instance.destroy());
    } else if (this.tippyInstance) {
      // Handle the case where tippyInstance is a single object
      this.tippyInstance.destroy();
    }
  }
}
