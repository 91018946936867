<ng-template #modalHistoryDriverTesting let-modal>
  <div class="modal-header text-center">
    <h5 class="modal-title">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="20"
      height="20"
    >
      <!-- Shield Outline -->
      <path
        d="M32 4L10 12v18c0 13.1 9.4 24.4 22 28.9 12.6-4.5 22-15.8 22-28.9V12L32 4z"
        fill="none"
        stroke="#34C759"
        stroke-width="3"
      />
      <!-- Checkmark -->
      <path
        d="M20 32l8 8 16-16"
        fill="none"
        stroke="#34C759"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span >ประวัติการทำแบบทดสอบ <span *ngIf="bookingNo">Booking : {{bookingNo}}</span></span></h5>
   
  </div>
  <div class="modal-body p-1">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12">
        <table class="table table-bordered table-hover table-striped w-100 table-sm">
          <thead class="bg-table">
            <tr>
              <th>คนขับ/เบอร์ติดต่อ</th>
              <th>ชื่อแบบทดสอบ</th>
              <th class="text-center">วัน/เวลา ทำแบบทดสอบล่าสุด</th>
              <th class="text-center">ผลการทดสอบ</th>
              <th class="text-center">สถานะผลทดสอบ</th>
              <th class="text-center">หมายเหตุติดตาม</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of driverTestingList">
              <tr>
                <td>
                  <app-icon-selector [selectedIcon]="item?.testStatusId"></app-icon-selector>
                  {{item?.driverFirstName}} {{item?.driverLastname}} ({{item?.driverMobileNo}})
                </td>
                <td>{{item?.testName}}</td>
                <td class="text-center">{{ item?.submitDate ? (item.submitDate | date:'dd/MM/yyyy HH:mm') : '-' }}  </td>
                
                <td class="text-center">{{item?.testResult}}</td>
                <td>
                  <span [ngClass]="{'text-red': item?.testStatusId == 2, 'text-green': item?.testStatusId == 3, 'text-green': item?.testStatusId == 4}">{{item?.testStatusName}}</span> 
                  <i class="fas fa-file-alt" *ngIf="item?.testStatusId == 2" style="margin-left: 5px;cursor: pointer;font-size: 15px;" (click)="onAgainTest(item)"></i> 
                  <br>
                  <span *ngIf="item?.approvedByUserId">
                    อนุมัติโดย :{{item?.approvedByUserName}} <br> 
                    {{ item?.approvedDate ? (item.approvedDate | date:'dd/MM/yyyy HH:mm') : '-' }}  
                  </span>
                </td>
                <td class="text-center">{{item?.monitoringReason}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="p-2">
    <div class="row pb-2 mt-2">
      <div class="col-12 text-right">
        <button
          type="button"
          class="btn btn-sm btn-secondary"
          (click)="modal.close('OK')"
        >ปิดหน้าต่าง</button>
      </div>
    </div>
  </div>
</ng-template>

